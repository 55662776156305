<template>
  <div>
    <!--begin: Head -->
    <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <div class="kt-user-card__avatar">
        <img
          class="fa loading"
          alt="Pic"
          :src="currentUser.avatar_url || 'assets/media/users/100_3.jpg'"
        />
        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        <span
          class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-hidden kt-font-success ">
          {{ typeof currentUser.nickname === 'string' ? currentUser.nickname.substr(0, 2) : '' }}</span
        >
      </div>
      <div class="kt-user-card__name">{{ currentUser.nickname }}</div>
    </div>
    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
      <router-link :to="{name: 'profile'}" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-calendar-3 kt-font-success"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">ئارخىبىم</div>
          <div class="kt-notification__item-time">
            ئارخىب تەھرىرلەش
          </div>
        </div>
      </router-link>
      <div class="kt-notification__custom kt-space-between">
        <a
          href="#"
          v-on:click="onLogout()"
          class="btn btn-label btn-label-brand btn-sm btn-bold"
          >چىكىنىش</a
        >
      </div>
    </div>
    <!--end: Navigation -->
  </div>
</template>

<script>
import { LOGOUT } from "@/store/auth.module";
import { mapGetters } from "vuex";
export default {
  name: "KTDropdownUser",
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    },
    ...mapGetters(['currentUser'])
  }
};
</script>

<template>
  <form>
    <!--begin: Head -->
    <div
      class="kt-head kt-head--skin-dark"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h3 class="kt-head__title">
        تېزلەتمە كونۇپكا
      </h3>
    </div>
    <!--end: Head -->

    <!--begin: Grid Nav -->
    <div class="kt-grid-nav kt-grid-nav--skin-light">
      <div class="kt-grid-nav__row">
        <a class="kt-grid-nav__item">
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M4 4l7.631-1.43a2 2 0 01.738 0L20 4v9.283a8.51 8.51 0 01-4 7.217l-3.47 2.169a1 1 0 01-1.06 0L8 20.5a8.51 8.51 0 01-4-7.217V4z" fill="#000" opacity=".3"/><path d="M11.175 14.75a.946.946 0 01-.67-.287l-1.917-1.917a.926.926 0 010-1.342c.383-.383 1.006-.383 1.341 0l1.246 1.246 3.163-3.162a.926.926 0 011.341 0 .926.926 0 010 1.341l-3.833 3.834a.946.946 0 01-.671.287z" fill="#000"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">تۈر</span>
          <span class="kt-grid-nav__desc">يېڭى تۈر قۇرۇش</span>
        </a>
        <a class="kt-grid-nav__item">
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.112 7.006A1.01 1.01 0 0014 7h-4a1.01 1.01 0 00-.112.006l-2.54-2.448a1 1 0 01-.028-1.412L8.42 2h7.16l1.1 1.146a1 1 0 01-.028 1.412l-2.54 2.448z" fill="#000"/><path d="M13.764 9l1.721 9.15a1 1 0 01-.26.877l-2.503 2.608a1 1 0 01-1.444 0l-2.502-2.608a1 1 0 01-.261-.878L10.236 9h3.528z" fill="#000" opacity=".3"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">فىلىم</span>
          <span class="kt-grid-nav__desc">يېڭى فىلىم قوشۇش</span>
        </a>
      </div>
      <div class="kt-grid-nav__row">
        <a class="kt-grid-nav__item">
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M10.586 12l-2.122 2.121a1 1 0 001.415 1.415L12 13.414l.707.707a2 2 0 010 2.829L9.88 19.778a2 2 0 01-2.829 0L4.222 16.95a2 2 0 010-2.829l2.828-2.828a2 2 0 012.829 0l.707.707z" fill="#000" opacity=".3"/><path d="M13.414 12l2.122-2.121a1 1 0 10-1.415-1.415L12 10.586l-.707-.707a2 2 0 010-2.829l2.828-2.828a2 2 0 012.829 0l2.828 2.828a2 2 0 010 2.829l-2.828 2.828a2 2 0 01-2.829 0L13.414 12z" fill="#000"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">ئېلان</span>
          <span class="kt-grid-nav__desc">يېڭى ئېلان قوشۇش</span>
        </a>
        <a class="kt-grid-nav__item">
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M21.748 7.099l-8.85 9.215a1.5 1.5 0 01-1.05.46l-3.723.079.079-3.738a1.5 1.5 0 01.442-1.033l9.05-8.999a1.5 1.5 0 012.118.003L21.727 5a1.5 1.5 0 01.021 2.1z" fill="#000" fill-rule="nonzero"/><path d="M12.9 2a1 1 0 010 2H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2v-5a1 1 0 012 0v5a4 4 0 01-4 4H6a4 4 0 01-4-4V6a4 4 0 014-4h6.9z" fill="#000" fill-rule="nonzero" opacity=".3"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">يازما</span>
          <span class="kt-grid-nav__desc">يېڭىدىن يازما قوشۇش</span>
        </a>
      </div>
    </div>
    <!--end: Grid Nav -->
  </form>
</template>

<script>
export default {
  name: "KTDropdownQuickAction",
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    }
  }
};
</script>
